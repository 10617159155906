import logo from './logo.svg';
import React from 'react';


import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';
import Modal from 'react-modal';

import MyGlobleSetting from './MyGlobleSetting';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

import Razorpay from './Razorpay';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";


axios.interceptors.request.use(function (config) {

    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
  
    const token = window.localStorage.token;
    if (token) {
       config.headers.Authorization = `token ${token}`
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(function (response) {
  
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
  
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

 

class Cart extends React.Component {


    constructor(props) {
        super(props);
      
    
        this.state = {
          reload:0,
          name:"",
          phone:"",
          email:"",
          modalIsOpen: false,
          modalQty:'',
          modalMessage: '',
          cartdata:[],
          openitem:'',
          tabledata:'',
          method:0,
          textarea:'',
          taxdata:[],
          refresh:0,
          loaded: false,
          settings:''
        };
      }

      toggleswitch(id,val)
      {
        
        //this.setState({ selectedbtn[id]: response.data.data });

      }



      validateinfo()
      {
        if(this.state.tabledata?.name == 'master_qr')
          {
            if(this.state.name.length < 2 )
              {
                return 'Enter Valid Name';
              }


              if(this.state.phone.length < 8 )
                {
                  return 'Enter Valid Phone Number';
                }

            var re = /\S+@\S+\.\S+/;
            if(this.state.email.length < 5 || !this.state.email.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ))
              {
                return 'Enter Valid Email';
              }

            return '';

          } else {
            return '';
          }
      }


  
  checktax()
  {

    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");


    const data = {
    
        token: token,
        tableid: tabledata,
      }
    axios.post(MyGlobleSetting.api+'/api/checktax', data).then((response) => {
           
     if(response.data.success==true) 
     
     {
      this.setState({ taxdata: response.data.data, refresh:1 });

    }
    else
    {
      swal({
       
        text: "Something went wrong  !!",
        icon: "error"
       
      });
    }



            });
         

  }







  checkstock(type)
  {
    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");
    const data = {
    
      token: token,
      tableid: tabledata,
    
    }
  axios.post(MyGlobleSetting.api+'/api/checkstock', data).then((response) => {
         
   if(response.data==0) 
   
   {
     
    this.checkpin();
  }
  else
  {

    this.getcart();
    swal({
     
      text: "Something went wrong with cart !!",
      icon: "error"
     
    });
  }


  });

  }

  afterpay()
  {
    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

    const data = {
    
      token: token,
      tableid: tabledata,
      textarea:this.state.textarea,
      name:this.state.name,
      phone:this.state.phone,
      email:this.state.email,
      tax:this.state.taxdata
    }
  axios.post(MyGlobleSetting.api+'/api/afterpay', data).then((response) => {
         
   if(response.data.success==true) 
   
   {
     swal({
     
      text: "Your order is successfully created  !!",
      icon: "success"
     
    });
    //localStorage.clear();
    this.props.history.push('/thanks');

  }
  else
  {
    swal({
     
      text: "Something went wrong !!",
      icon: "error"
     
    });
  }


  });



  }
  checkpin()
  {

    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

    var pincode=$(".pincode").val();

    if(pincode=='')
    {
      swal({
   
        text: "Please Enter a valid PIN",
       
      });
      return false;
    }
    const data = {
    
        token: token,
        tableid: tabledata,
        pincode: pincode,
        textarea:this.state.textarea,
        name:this.state.name,
      phone:this.state.phone,
      email:this.state.email,
        tax:this.state.taxdata
      }
    axios.post(MyGlobleSetting.api+'/api/checkpin', data).then((response) => {
           
     if(response.data.success==true) 
     
     {
       swal({
       
        text: "Your order is successfully created  !!",
        icon: "success"
       
      });
      //localStorage.clear();
      this.props.history.push('/thanks');

    }
    else
    {
      swal({
       
        text: "Invalid PIN or Table have existing order !!",
        icon: "error"
       
      });
    }


    });
         

  }





  closeModal() {
    this.setState({ modalIsOpen: false });
  }



  goto_page(page)
  {
  
    this.props.history.push(page)
  }




  
  verifyOrder()
  {
    var t = this.validateinfo();
    if(t.length > 0)
      {
        swal({
       
          text: t,
          icon: "warning"
         
        });

        return false;
      }
    var token=localStorage.getItem("devicetoken");
    const data = {
    
        token: token
      }
      
    axios.post(MyGlobleSetting.api+'/api/verifyCart', data).then((response) => {
           

      if(response.data == 1)
      {
        this.checktax();
      } else {

        this.setState({modalMessage: response.data.menu, modalQty: response.data.qty, modalIsOpen:true});
        
      }
      //  this.setState({ cartdata: response.data.data });


            });




  }

  

  getcart()
  {

    var token=localStorage.getItem("devicetoken");
    const data = {
    
        token: token
      }
    axios.post(MyGlobleSetting.api+'/api/getcart', data).then((response) => {
           
        this.setState({ cartdata: response.data.data });


            });




  }

  

  gettax()
  {

    var token=localStorage.getItem("devicetoken");
    const data = {
    
        token: token
      }
    axios.get(MyGlobleSetting.api+'/api/gettax').then((response) => {
           
        this.setState({ taxdata: response.data.data });


            });




  }

  

  squareupPay (nonce, amount)
  {
    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

 
    const data = {
      token: token,
        tableid: tabledata,
            nonce: nonce.token,
            amount:amount,
            textarea:this.state.textarea,
            name:this.state.name,
      phone:this.state.phone,
      email:this.state.email,
          }
          axios.post(MyGlobleSetting.api+'/api/process-sqaureup', data).then((response) => {
           
            document.body.classList.remove('loading-indicator');
     if(response.data.success==true) 
     
     {
       swal({
       
        text: "Your order is successfully created  !!",
        icon: "success"
       
      });
      //localStorage.clear();
    window.location.href="/thanks";

    }
    else
    {
      swal({
        
        text: "Payment Failed !!",
        icon: "error"
       
      });
    }
       
       
                   });
  }



    componentDidMount(){

           
     
      var token = localStorage.getItem("devicetoken");
      var tabledata = localStorage.getItem("tableid");

      var currencyIcon = localStorage.getItem("currencyIcon");
      localStorage.clear();

   localStorage.setItem("devicetoken",token);
   localStorage.setItem("tableid",tabledata);
   localStorage.setItem("currencyIcon",currencyIcon);  
     



        this.getcart();
        this.gettax();
        this.tabledata();
        this.getsetting();
      }

      getsetting()
      {
        axios.get(MyGlobleSetting.api+'/api/getsetting').then((response) => {
             
      
          const that = this;
          let sqPaymentScript = document.createElement('script');

            // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform

         // if(response.data.data.sq_type==0)
         // sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
         // else
          sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
          sqPaymentScript.type = "text/javascript"
          sqPaymentScript.async = false;
          sqPaymentScript.onload = ()=>{that.setState({
            loaded: true
          })};
          document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
          
          
        
            this.setState({settings:response.data.data});
           
           
         
        
        
                });
      }



      tabledata()
      {


        const data = {
    
          id: localStorage.getItem("tableid")
        }
      axios.post(MyGlobleSetting.api+'/api/checktable', data).then((response) => {
             
      
        if(response.data.success==true)
        {
      
          this.setState({tabledata:response.data.data});
         
         
        }
        else
        {
          swal({
       
            text: "You have not select any table, Please scan QR",
           
          });
        }
        
      
      
              });



      }


      goto_delete(id)
      {


        const customer = {
            id: id
          }
        
    
    
          axios.post(MyGlobleSetting.api+'/api/deletecartbyid', customer).then((response) => {

            swal({
       
                text: "Item is successsfully deleted from cart !!",
                icon: "success"
               
              });


            this.getcart();
        });



      }

      goto_edit(id)
      {
        this.props.history.push({
          pathname: '/edit',
          search: '?id=' + id,
          state: { id: id}
        })
      }
    


    render(){

     var totprice=0; 
     var alltotprice=0; 
     var isval = 0;

     const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '320px'
      },
    };


  return (
    <div className="App">






          <div className="site-content">
      
 
          <header className="site-header header-style-one intro-element header-absolute pages-header" style={{background:"white",borderBottom:"2px solid lightgrey"}}>
            <div className="navigation-area">
                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <div className="site-navigation">
                                <div className="site-branding">
                                    <h4 >
                                        My Cart 
                                    </h4>
                                </div>

                                <div className="header-right-area">
                                   
                                    
                                   <div className="header-card-area" style={{marginRight:"15px"}}>
                                   <button type="button"  onClick={()=>this.goto_page('/')} class="btn btn-primary">Continue Shopping</button>
                                   </div>
                                

                                  
                               </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
        </header>






        <div class="shopping-cart-block pl-1 pr-1" style={{paddingTop:"70px"}}>
          

            


                                {this.state.cartdata!='' && this.state.cartdata != null ? this.state.cartdata.map((data, i) => {

                                    var cls="container p-0 mt-3";


                                    var selectopt= JSON.parse(data.data);

var colrowclas="row m-2 mb-3 display-none";

if(this.state.openitem==data.id)
colrowclas="row m-2 mb-3";

var is_pro =0;
if(data.menu.is_product==1 && data.menu.product!= null && data.menu.product!= undefined && data.menu.product.stock < data.qty && data.menu.product.is_negative==0)
  {
is_pro =1 ;
  }
 var tt = data.menu.price;
if(data.is_edit==0 && is_pro ==0)
{

  if(data.menu.menumodifier!='' && data.menu.menumodifier != null )
  { data.menu.menumodifier.map((subdata, i) => {

    var newdata= JSON.parse(subdata.modifier_data);

    if((newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '')
    {
      if( (newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '' ) {
        (newdata.button_name).map((btn, b) => {

          if(newdata.price[b]=='' || newdata.price[b]==null || newdata.price[b]==0)
 newdata.price[b]=0;


 newdata.price[b]=parseFloat(newdata.price[b]);

 var toggleclass='toggle_'+subdata.id+'_'+b;

 var qtyclass='qty_'+subdata.id+'_'+b;

 var priceclass='price_'+subdata.id+'_'+b;

 newdata.price[b] = parseFloat(selectopt[priceclass]);
var thiselect='display-none';
   if(selectopt[toggleclass]!=undefined && selectopt[toggleclass]!=null && selectopt[toggleclass]==1)
   {
tt = tt+newdata.price[b];
   }
      });
    }
    }

  });
}

tt = tt*data.qty;
var stt = tt;
  var val = 0;
  var val1 = 0;
  if(data.surcharge!='' && data.surcharge != null  && data.surcharge.length>0)
  {
    data.surcharge.map((surcharge, i) => {
    if(surcharge.type==0)
    {
       val += data.qty*surcharge.value;
       val1 += surcharge.value;
    } else {
       val += (parseFloat(tt)*surcharge.value)/100;
       val1 += (parseFloat(tt)*surcharge.value)/100;
    }

  });
  }
 
  tt = tt+val;

  totprice = totprice+tt;
  
var notAv = 0;
  if(data.menu.todaydisabled!= null && data.menu.todaydisabled.length != 0)
  {
    var notAv = 1;
    isval = 1;
  }
        return (
            
            <div className={cls} style={{border:"1px solid grey"}}>

<div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({openitem:data.id})}>


<h5 style={{width:"100%",marginBottom:"0px"}}>{data.qty} X {data.menu.name} <span style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {((data.qty*data.price)+val).toFixed(2)}</span>

{notAv==1 ? <span style={{color:'red'}}>(Not Available)</span>: '' }</h5>


    </div>

  
    <div className={colrowclas} >
           
                      
<div className="col-md-12 p-0 pl-2 pr-2">


<h5 style={{width:"100%",marginBottom:"0px"}}><span onClick={()=>this.goto_edit(data.id)}><span class="icon-pencil2"  ></span> Edit</span> &nbsp; &nbsp; &nbsp; <span onClick={()=>this.goto_delete(data.id)}><span class="icon-cross"></span> Remove </span> 


<span style={{float:"right"}}> Base : <span style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {(data.menu.price).toFixed(2)}</span>



</span></h5>
</div>




                      {data.menu.menumodifier!='' && data.menu.menumodifier != null ? data.menu.menumodifier.map((subdata, i) => {
 if(subdata.type==1)
 {
 var cls="container p-0 mt-3";
 }
 else
 {
    var cls="container p-0 mt-3 display-none";
 }

 var newdata= JSON.parse(subdata.modifier_data);
var optiontext='';

var checkedoption=0;



var nomore=0;

var trcls="table";

var checkit=0;
if((newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '')
{
(newdata.button_name).map((btn, b) => {


    var toggleclass='toggle_'+subdata.id+'_'+b;

      if(selectopt[toggleclass]!=undefined && selectopt[toggleclass]!=null && selectopt[toggleclass]==1)
      {
        checkit++;
      }
   
  



});


}



if(checkit==0)
{
    cls=cls+' display-none';
}
 
return (

    <div className={cls} style={{border:"1px solid grey",}}>

<div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({selectedmodifierdata:subdata.id})}>
{subdata.name} 
    </div>

    <table className={trcls} style={{marginBottom:"0px"}}>

    { (newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '' ?(newdata.button_name).map((btn, b) => {


if(newdata.price[b]=='' || newdata.price[b]==null || newdata.price[b]==0)
 newdata.price[b]=0;


 newdata.price[b]=parseFloat(newdata.price[b]);




 var toggleclass='toggle_'+subdata.id+'_'+b;

 var qtyclass='qty_'+subdata.id+'_'+b;
 var priceclass='price_'+subdata.id+'_'+b;

var thiselect='display-none';
   if(selectopt[toggleclass]!=undefined && selectopt[toggleclass]!=null && selectopt[toggleclass]==1)
   {
    thiselect='';
   }

  var thisqty=1;
  
  if(selectopt[qtyclass]!=undefined && selectopt[qtyclass]!=null && selectopt[toggleclass]==1)
  {
    thisqty=selectopt[qtyclass];
  }

  newdata.price[b] = selectopt[priceclass];

return (

    <tr class={thiselect}>
        <th style={{textAlign:"left"}}>{thisqty} X {newdata.button_name[b]} </th>

        <th style={{textAlign:"right"}}>{localStorage.getItem("currencyIcon")}{(thisqty*newdata.price[b]).toFixed(2)} </th>

   

        
    </tr>


)

    }) : "" }


</table>


    </div>
)

                }) : "" }

  <div class="col-md-12 p-0 pl-2 pr-2" style={{marginTop:"5px"}}>
    <h5 style={{width:"100%",color:"grey"}}>
<span style={{float:"right",padding:"0px !important"}}>Sub Total: <span>{(stt).toFixed(2)}</span></span><br/>
{data.surcharge!='' && data.surcharge != null  && data.surcharge.length>0 ? data.surcharge.map((surcharge, i) => {

if(surcharge!= '' && surcharge != null) {
var val=0;
  if(surcharge.type==0)
  {
    val = data.qty*surcharge.value;
  } else {
    val = (parseFloat(data.price)*data.qty*surcharge.value)/100;
  }
  return (
<span>
 

{surcharge.name} {surcharge.type == 1 ? "("+surcharge.value+"%)":"" }:&nbsp;<span style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {val.toFixed(2)}</span>  <br/>
</span> 
  )
}
}):""}
<span style={{float:"right",padding:"0px !important",border:"none"}}>Total: <span>{((data.qty*data.price)+val).toFixed(2)}</span></span>
</h5>
  </div>
  </div>
</div> 

);

              }


              else
              {
return (
<div className={cls} style={{border:"1px solid red"}}>
  <div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} >


<h5 style={{width:"100%",marginBottom:"0px"}}>{data.qty} X {data.menu.name} <span onClick={()=>this.goto_delete(data.id)} style={{color:"red"}}><span class="icon-cross"></span> Remove  {is_pro == 1 ? "(Out of Stock)":""} </span> <span style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {(data.qty*data.price).toFixed(2)}</span></h5>


    </div>
</div>
    
);
                
              }
}) : ""}



                                  
<div className=" mt-3 pl-2 pr-2">
<label><b>Special Instructions</b></label>
<center>

<textarea className="form-control" name="textarea" value={this.state.textarea} onChange={(e)=>this.setState({textarea:e.target.value})}placeholder="Special Instructions" rows="1"></textarea>

  </center>
</div>                              
                                

<div class="cart-total-area mt-3">
                            <div class="cart-total-info">
                                <table class="table pb-4" style={{borderBottom:"1px solid grey"}}>
                                    <tr>
                                        <th style={{textAlign:"left",fontSize:"18px"}}>Sub Total</th>
                                        <th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")} {totprice.toFixed(2)}</th>
                                    </tr>

                                    {this.state.settings != "" && this.state.settings != null && this.state.settings.tax_type == 0 && totprice>0 && this.state.taxdata!='' && this.state.taxdata != null ? this.state.taxdata.map((t, i) => {

if(alltotprice==0)
{
  alltotprice=totprice;
}
var txt='';
var tamt=t.value;
  if(t.type==1)
  {
    txt='('+t.value+'% of '+alltotprice.toFixed(2)+')';

    tamt=(alltotprice*t.value)/100;
  }


  totprice=parseFloat(totprice)+parseFloat(tamt);

return (
<tr>
<th style={{textAlign:"left",fontSize:"18px"}}>{t.name}   {txt} </th>
<th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")} {tamt.toFixed(2)}</th>
</tr>




                                             )         }): '' }

                         {this.state.settings != "" && this.state.settings != null && this.state.settings.tax_type == 1 ?
                         <tr>
                         <th style={{textAlign:"left",fontSize:"18px"}}>Tax included in Total </th>
                         <th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")} { (totprice - ( totprice * (100 / (100 + this.state.settings.tax_per)))).toFixed(2)}</th>
                         </tr>
                        :"" }           
                                </table>
                                
                                
                                <h3 class="cart-total-amount mt-2"><span>order total</span> <span
                                        class="amount">{localStorage.getItem("currencyIcon")} {totprice.toFixed(2)}</span></h3>
                            </div>


                            <div class="text-center">
                            {this.state.tabledata!=undefined && this.state.tabledata!=null && this.state.tabledata !='' && this.state.tabledata?.name != "master_qr" ?
                                  
                                 
                                  <h4>Order For <br/><b>                                  <span style={{ color: this.state.header_font}}>{this.state.tabledata?.floors?.name ? <span>{this.state.tabledata?.floors?.name} -  </span> : "" }  {this.state.tabledata.name == 'master_qr' ? "QR Order" : this.state.tabledata.name}</span></b></h4>

                                  :

                               this.state.tabledata?.name != "master_qr"  ? <h4 >No Table Selected  <br/>
                                  
                                  <b>For Checkout Plese Scan QRCode first !!</b></h4>:

                                  ""
                                }

</div>
{this.state.tabledata?.name == "master_qr"  ?

<div className="row">
  <h5>Your Details</h5>
  <div className='col-md-12 mb-2'>
                                    <label>Name</label>
                                    <input type="text" disabled={this.state.refresh == 0 ? false: true} onChange={(e)=>this.setState({name:e.target.value})} className='form-control name'/>
                                    </div> 

                                    <div className='col-md-12 mb-2'>
                                    <label>Phone</label>
                                    <input type="text"  disabled={this.state.refresh == 0 ? false: true} onChange={(e)=>this.setState({phone:e.target.value})} className='form-control phone'/>
                                    </div> 

                                    <div className='col-md-12'>
                                    <label>Email</label>
                                    <input type="text" disabled={this.state.refresh == 0 ? false: true}   onChange={(e)=>this.setState({email:e.target.value})} className='form-control email'/>
                                    </div> 




                                    </div>
                                    
                                    
                                    
                                    :""}
<br/>


{isval == 0 ? this.state.refresh==0 && totprice>0 ?
<div class="text-center">


  <button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.verifyOrder()}  >Order Now</button> 
  
</div>
: ''  : <div class="text-center">
  <p style={{color:'red'}}>One or more selected item is unavailable please remove this and add another item. </p>
  
  </div> }


{this.state.refresh==1 && this.state.method==0 && this.state.tabledata!=undefined && this.state.tabledata!=null && this.state.tabledata !='' ?


                            <div class="text-center">

{  this.state.settings!='' && this.state.settings.after_pay==1 && this.state.settings.pay_by_pin ==0 ?   
    <button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.afterpay()}>Pay After</button> 

    : '' }


{  this.state.settings!='' && this.state.settings.pay_by_pin==1 &&   this.state.tabledata?.name != "master_qr" ?   
    <button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.setState({method:1})}>I HAVE PIN</button> 

    : '' }
                                {  this.state.settings!='' && this.state.settings.is_qr_payment==1 && (this.state.settings.pgay==1 || this.state.settings.pgay==0)?    
                               
                                
                                <button type="button" class="btn mt-2 btn-default ml-1 mr-1" onClick={()=>this.setState({method:'paymentmethod'})}>PAY NOW</button>
                                :'' }

{  this.state.settings!='' && this.state.settings.is_qr_payment==1 &&  (this.state.settings.pgay==2)?    
                               
                                
                               <Razorpay  name={this.state.name} email={this.state.email} phone={this.state.phone} textarea={this.state.textarea} data={totprice} settings={this.state.settings} />
                               :'' }
                            </div>
:''

                              }


      {this.state.refresh==1 && this.state.method==1 && totprice>0  ?


      <div class="text-center">

        <input type="text" className="form-control pincode" placeholder="Enter Pin" />
          <button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.checkstock(1)} >CHECKOUT</button> <br/>
          <button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.setState({method:0})}>Back</button> 
      </div>
:''

                              }



{this.state.refresh==1 && this.state.method=='paymentmethod' && totprice>0 && this.state.settings!='' && this.state.settings.is_qr_payment==1 && this.state.settings.pgay==0  ?


<div>
  <h4>Pay By Stripe</h4>
  <label>Card Details </label>


<Elements stripe={loadStripe(this.state.settings.stripe_key)}  name={this.state.name} email={this.state.email} phone={this.state.phone} textarea={this.state.textarea} data={totprice}  >
  <StripeCheckoutForm  name={this.state.name} email={this.state.email} phone={this.state.phone}  textarea={this.state.textarea} data={totprice} />
</Elements>
<Elements stripe={loadStripe(this.state.settings.stripe_key)}  name={this.state.name} email={this.state.email} phone={this.state.phone} textarea={this.state.textarea}  data={totprice}  >
  <CheckoutForm  name={this.state.name} email={this.state.email} phone={this.state.phone} textarea={this.state.textarea} data={totprice} />
</Elements>

<center><button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.setState({method:0})}>Back</button> </center>
</div>
: ''}


{this.state.refresh==1 && this.state.method=='paymentmethod' && totprice>0 && this.state.settings!='' && this.state.settings.is_qr_payment==1 && this.state.settings.pgay==2  ?
<Razorpay  name={this.state.name} email={this.state.email} phone={this.state.phone} textarea={this.state.textarea} data={totprice} settings={this.state.settings} />

: "" }

{this.state.refresh==1 && this.state.method=='paymentmethod' && totprice>0 && this.state.settings!='' && this.state.settings.is_qr_payment==1 && this.state.settings.pgay==1  ?

<div>


<PaymentForm
    
    applicationId={this.state.settings.app_id}
    cardTokenizeResponseReceived={(token, buyer) => 
      this.squareupPay(token, totprice)
    }
    locationId={this.state.settings.location_id}
  >
    <CreditCard />
  </PaymentForm>

{/* {this.state.loaded==true?
  <PaymentForm
          paymentForm={ window.SqPaymentForm }
          setting={this.state.settings}  textarea={this.state.textarea} data={totprice} />
:<center><label>Wait a moment...</label></center>} */}
{/* <center><button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.setState({method:0})}>Back</button> </center>
      */}
        </div>
: ''}
   
                           
                        </div>
            </div>
        </div>

        <Modal
        isOpen={this.state.modalIsOpen}
       
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Alert"
      >
        <center><h2>Alert  </h2></center>

       <b><p> For the following </p>
       
     <ul>
      {this.state.modalMessage != '' && this.state.modalMessage != undefined && this.state.modalMessage.length > 0 ?
      this.state.modalMessage.map((data, i) => {
return (
  <li>{data}</li>
)
      }) : ''
      }
     </ul>
    <p>In total {this.state.modalQty} serves are available. Please adjust your order accordingly. </p>
       
    </b>

        <center><button className="btn btn-default" style={{float:'right'}} onClick={() => this.closeModal()}>OK</button></center>
        
      </Modal>

    </div>
  );
}
}

export default Cart;
